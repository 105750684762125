import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { VictoryChart, VictoryLine, VictoryAxis } from "victory";

const ThirdLaw = () => {
  return (
    <>
      <div className="about-content pt-13 pb-13">
        <Container>
          <Row>
            <Col lg="7" className="mb-7 mb-lg-0">
              <div className="pr-xl-13">
                <h2 className="gr-text-4 mb-8">
                  Newton’s 3rd law states that for every force there is an equal
                  & opposite reaction.
                </h2>
                <p className="gr-text-9">
                  The increased ability of a watercraft using our patented
                  constructed method to dampen out high frequency impacts,
                  vibration and resonance comes from the reaction that occurs at
                  a molecular level when a shear load is applied to the
                  Non-Newtonian material (also known as a dilatant material).
                </p>
                <p className="gr-text-9">
                  The molecular chains in the polymer change shape, and become
                  more rigid than in their non-excited state.
                </p>
                <p className="gr-text-9">
                  The instant that the shear load (i.e. the micro-bending caused
                  by impacts etc.) is reduced, the molecular structure of the
                  dilatant material relaxes and returns to its more pliable
                  state.
                </p>
                <p className="gr-text-9">
                  This action and reaction causes the Non-Newtonian material to
                  act as an energy sink, absorbing energy that would normally be
                  transmitted throughout the structural components in a hull, or
                  directly absorbed by the watercraft’s rider(s).
                </p>
              </div>
            </Col>
            <Col lg="5" className="pt-lg-32">
              <div className="pr-xl-13">
                <div className="pb-8">
                  <VictoryChart
                    animate={{
                      duration: 1000,
                      onLoad: { duration: 500 },
                      easing: "quadOut",
                    }}
                    domainPadding={{ x: 20, y: 30 }}
                  >
                    <VictoryAxis
                      label="Watercraft Speed"
                      tickLabelComponent={<></>}
                    />
                    <VictoryAxis
                      dependentAxis
                      label="Damping Ratio"
                      tickLabelComponent={<></>}
                    />
                    <VictoryLine
                      data={victData}
                      x="speed"
                      y="damp"
                      interpolation="natural"
                    />
                  </VictoryChart>
                </div>
                <h2 className="gr-text-4 mb-8 text-right">
                  We use non-Newtonian materials to break the 3rd law.
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const victData = [
  { speed: 5.58, damp: 4.65 },
  { speed: 6.85, damp: 17.71 },
  { speed: 8.3, damp: 85 },
];

export default ThirdLaw;
