import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import imgBg from "../../assets/image/png/transparent_repeat_vibes.png";

const Testing = () => {
  return (
    <>
      <div
        style={{
          background: `url(${imgBg}) center no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Container className="py-28">
          <Row className="justify-content-center">
            <Col lg="8" sm="10" className="text-center">
              <h2 className="mb-12">Testing</h2>
              <p className="mb-18">
                Our construction technology has undergone various forms of
                testing, utilizing standardized geometry and test blanks.
              </p>
              <p>
                Through the application of an impulse to initiate vibration, the
                damping ratio of each test blank was calculated using log
                decrement and then averaged. The vibration (and damping ratios)
                were measured by recording the acceleration that each test blank
                experienced through the resulting oscillations.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Testing;
