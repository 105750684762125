import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import ThirdLaw from "../sections/about/ThirdLaw";
import Testing from "../sections/about/Testing";
import Engineering from "../sections/about/Engineering";

const AboutPage = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
        headerConfig={{
          align: "right",
          isFluid: true,
          button: null, // cta, account, null
        }}
      >
        <div>
          <div className="inner-banner">
            <Container>
              <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
                <Col lg="9" xl="8">
                  <div className="px-md-12 text-center mb-11">
                    <h2 className="title gr-text-3 mb-9">
                      Testing & Engineering
                    </h2>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <ThirdLaw />
          <Testing />
          <Engineering />
        </div>
      </PageWrapper>
    </>
  );
};

export default AboutPage;
