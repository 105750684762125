import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imgSurfStress from "../../assets/image/png/transparent_surfboard_stress.png";

const Engineering = () => {
  return (
    <>
      <div>
        <Container className="my-12 pt-14">
          <Row className="align-items-center my-18">
            <Col lg="6" className="order-2 order-lg-1">
              <div className="about-image img-big">
                <img src={imgSurfStress} className="w-100 mb-12 pr-lg-12" />
              </div>
            </Col>
            <Col lg="6" className="order-1 order-lg-2">
              <h4 className="mb-5">Engineering Implications</h4>
              <p className="gr-text-9">
                The standardized blanks that were used in our testing and
                validation did more than prove the validity of our technology,
                but they in no way encompassed the improvements that we believe
                are attainable with this technology.
              </p>
              <p className="gr-text-9">
                With the incredible variation in hull geometry and watercraft design that
                exists today, each case-use must be individually analyzed,
                considering the specific performance characteristics that are
                desired, and the changes in flex patterns and nodal points that
                occur with geometric changes.
              </p>
              <p className="gr-text-9">
                Besides the increased craft and rider performance that our
                technology offers, we also hope to see increased fatigue life in
                lightweight composite watercraft by an order of magnitude, due
                to the overall reduction in energy absorption and transmittance
                that any watercraft with our technology will experience.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Engineering;
